import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import { AddCircle as AddIcon, RemoveCircle as RemoveIcon, Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { ButtonBox, WorktimeSpan, WorkHourChip, FlexIconButton } from "./FlexStyle";
import { ActivityBox } from "./ActivityBox";
import { EditTime } from "./EditTime";
import { formattedTime, formattedWorktime, weekdays } from "../../Helpers";
import { EndTime } from "./EndTime";
import { PlusMinusMinutes } from "./PlusMinusMinutes";
import { EWorkType, IActivity, IDate, ITimeItem } from "./FlexInterfaces";
import { v4 as uuidv4 } from "uuid";

interface CalculatedTimes {
  time: Date,
  minutes: number,
}

const getActivityTemplate = (): IActivity => {
  return {
    id: uuidv4(),
    rowIndex: 0,
    type: EWorkType.Customer,
    minutes: 0,
    note: "",
    credit: 0,
    active: true,
    existing: false,
  };
}

interface FlexTodayProps {
  item: ITimeItem,
  saveCallback: (item: ITimeItem) => void,
  resetCallback: () => void,
}

const convertToDate = (first: IDate, secondary?: IDate) => {
  const now = new Date();
  return new Date(
    first.year,
    first.month - 1,
    first.day,
    secondary ? secondary.hour : now.getHours(),
    secondary ? secondary.minute : now.getMinutes(),
  )
}


const FlexToday = (props: FlexTodayProps) => {
  const [startTime, setStartTime] = useState<Date>(convertToDate(props.item.start, props.item.start));
  const [endTime, setEndTime] = useState<Date>(convertToDate(props.item.start, props.item.end));
  const [endTimeActivated, setEndTimeActivated] = useState<boolean>(!!props.item.end);
  const [lunchTime, setLunchTime] = useState<number>(props.item.lunchMinutes);
  const [absence, setAbsence] = useState<number>(props.item.absenceMinutes);
  const [vacation, setVacation] = useState<boolean>(props.item.vacation);
  const [activities, setActivities] = useState<IActivity[]>(props.item.activities);

  const [calculatedEndTimes, setCalculatedEndTimes] = useState<CalculatedTimes[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  // Disable edit mode when changing item
  useEffect(() => {
    setEditMode(false);
  }, [props.item.id]);

  useEffect(() => {
    if (!props.item.start) {
      return;
    }
    setStartTime(convertToDate(props.item.start, props.item.start));
    setEndTime(convertToDate(props.item.start, props.item.end));
    setEndTimeActivated(!!props.item.end);
    setLunchTime(props.item.lunchMinutes);
    setAbsence(props.item.absenceMinutes);
    setVacation(props.item.vacation);
    setActivities(props.item.activities.length > 0 ? props.item.activities : [getActivityTemplate()]);  
  }, [props]);

  const save = () => {
    const newItem: ITimeItem = {
      id: props.item.id,
      start: {
        year: startTime.getFullYear(),
        month: startTime.getMonth() + 1,
        day: startTime.getDate(),
        hour: startTime.getHours(),
        minute: startTime.getMinutes(),
      },
      end: endTimeActivated ? {
        year: endTime.getFullYear(),
        month: endTime.getMonth() + 1,
        day: endTime.getDate(),
        hour: endTime.getHours(),
        minute: endTime.getMinutes(),
      } : null,
      totalWorkMinutes: 0,
      lunchMinutes: lunchTime,
      absenceMinutes: absence,
      vacation: vacation,
      existing: props.item.existing,
      activities: activities,
    };
    props.saveCallback(newItem);
  }

  const sortActivities = (a: IActivity, b: IActivity) => {
    return a.rowIndex > b.rowIndex ? 1 : -1;
  }

  const addActivity = useCallback(() => {
    const newActivity = { ...getActivityTemplate(), rowIndex: activities.length };
    setActivities(prevActivities => [...prevActivities, newActivity].sort(sortActivities));
  }, [activities.length]);

  const handleAddTime = (type: 'start' | 'end', minutes: number) => {
    if (type === 'start') {
      const newStartTime = new Date(startTime);
      newStartTime.setMinutes(newStartTime.getMinutes() + minutes);
      setStartTime(newStartTime);
    } else {
      const newEndTime = new Date(endTime);
      newEndTime.setMinutes(newEndTime.getMinutes() + minutes);
      setEndTime(newEndTime);
      setEndTimeActivated(true);
    }
  };

  const getEstimatedWorktime = () => {
    const credits = activities.reduce((acc, cur) => acc + cur.credit, 0);
    const diff = (endTime.getTime() - startTime.getTime()) / 60_000 - lunchTime + credits;
    return diff > 0 ? diff : 0;
  }

  const getCalculatedWorktime = () => {
    return activities.reduce((acc, cur) => acc + cur.minutes + absence + 8 * 60 * +vacation, 0);
  }

  const getLegendTitle = (date: Date): string => {
    if (!date) {
      return "";
    }
    return `${weekdays[date.getDay()]} ${date.getDate()}/${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  useEffect(() => {
    if (!startTime) {
      return;
    }
    // "now" (if within reason), 7.5h, 8h, 8.5h
    let calcTimes = [7.5 * 60, 8 * 60, 8.5 * 60];
    const credits = activities.reduce((acc, cur) => acc + cur.credit, 0);
    const checkOutNowMinutes = Math.floor((new Date().getTime() - startTime.getTime()) / (60*1000)) - lunchTime + credits - absence;
    if (checkOutNowMinutes > 0 && checkOutNowMinutes < 24*60) {
      calcTimes = [checkOutNowMinutes, ...calcTimes];
    }
    setCalculatedEndTimes(calcTimes.map((c) => {
      return {
        time: new Date(+startTime.getTime() + (c + lunchTime - credits + absence) * 60000),
        minutes: c,
      };
    }));
  }, [startTime, lunchTime, activities, absence])

  const toggleEditMode = () => {
    setEditMode(prev => !prev);
  }

  const updateValue = {
    startTime: useCallback((value: Date) => {
      setStartTime(value);
    }, []),
    endTime: useCallback((value: Date) => {
      setEndTime(value);
    }, []),
    lunch: (value: number) => {
      setLunchTime(value);
    },
    activity: useCallback((activity: IActivity) => {
      setActivities(prevActivities => [...prevActivities.filter(act => act.id !== activity.id), activity].sort(sortActivities));
    }, []),
    absence: (value: number) => {
      setAbsence(value);
    },
    vacation: (value: number) => {
      setVacation(value === 1);
    },
  }
  
  return (
    <Box sx={{ position: "relative" }}>
      <ButtonBox>
        <div><Button onClick={addActivity} variant="outlined" startIcon={<AddIcon />}>Ny post</Button></div>
        <div><Button onClick={toggleEditMode} variant="outlined" startIcon={<EditIcon />}>Redigera</Button></div>
        <div><Button onClick={save} variant="outlined" startIcon={<SaveIcon />}>Spara</Button></div>
      </ButtonBox>
      <Box display="flex" justifyContent="center">
        <Box width="60%" component="fieldset">
          <legend>{getLegendTitle(startTime)}</legend>
          <Grid container spacing={0.5} alignItems="center">
            <Grid item>
              {!editMode && <FlexIconButton onClick={() => handleAddTime("start", -5)}>
                <RemoveIcon />
              </FlexIconButton>}
            </Grid>
            <Grid item>
              {!editMode && <Typography variant="body1">{formattedTime(startTime)}</Typography>}
              {editMode && <EditTime value={startTime} callback={updateValue.startTime} />}
            </Grid>
            <Grid item>
              {!editMode && <FlexIconButton onClick={() => handleAddTime("start", 5)}>
                <AddIcon />
              </FlexIconButton>}
            </Grid>
            <Grid item>
              <Typography variant="body1">{'\u2014'}</Typography>
            </Grid>
            <Grid item>
              {!editMode && <FlexIconButton onClick={() => handleAddTime("end", -5)}>
                <RemoveIcon />
              </FlexIconButton>}
            </Grid>
            <Grid item>
              <EndTime
                editMode={editMode}
                endTime={endTime}
                endTimeActivated={endTimeActivated}
                callback={updateValue.endTime} />
            </Grid>
            <Grid item>
              {!editMode && <FlexIconButton onClick={() => handleAddTime("end", 5)}>
                <AddIcon />
              </FlexIconButton>}
            </Grid>
            <Grid item>
              <WorktimeSpan>{formattedWorktime(getEstimatedWorktime())} =&gt; {formattedWorktime(getCalculatedWorktime())}</WorktimeSpan>
              {calculatedEndTimes.map((et, index) => <WorkHourChip
                key={index}
                size="small"
                onClick={() => {
                  setEndTime(et.time);
                  setEndTimeActivated(true);
                }}
                label={`${formattedTime(et.time)} = ${formattedWorktime(et.minutes)}`} />)}
            </Grid>
          </Grid>
          {activities.map(act => <ActivityBox key={act.id} activity={act} editMode={editMode} callback={updateValue.activity} />)}
          <PlusMinusMinutes initialValue={lunchTime} label="Lunch/rast" callback={updateValue.lunch} />
          {editMode && <PlusMinusMinutes initialValue={absence} label="Frånvaro" callback={updateValue.absence} allDay={true} />}
          {editMode && <PlusMinusMinutes initialValue={vacation ? 1 : 0} label="Semester" callback={updateValue.vacation} useSwitch={true} />}
        </Box>
      </Box>
    </Box>
  );
};

export default FlexToday;
