import { FormControlLabel, IconButton, Radio, TextField, Grid } from "@mui/material"
import { ActivityGrid, NoWrapRadioGroup } from "./FlexStyle"
import { useEffect, useState } from "react"
import { AddCircle as AddIcon, RemoveCircle as RemoveIcon } from '@mui/icons-material';
import { EPlusMinusType, PlusMinusMinutes } from "./PlusMinusMinutes";
import { EWorkType, IActivity } from "./FlexInterfaces";

interface ActivityBoxProps {
  activity: IActivity,
  editMode: boolean,
  callback: (act: IActivity) => void,
}

export const ActivityBox = ({ activity, editMode, callback }: ActivityBoxProps) => {
  const [type, setType] = useState<string>(activity.type);
  const [note, setNote] = useState<string>(activity.note);
  const [credit, setCredit] = useState<number>(activity.credit);
  const [active, setActive] = useState<boolean>(activity.active);
  const [minutes, setMinutes] = useState<number>(activity.minutes);

  useEffect(() => {
    const convertedType = type as EWorkType;
    const newActivity: IActivity = {
      id: activity.id,
      rowIndex: activity.rowIndex,
      type: convertedType,
      minutes,
      note,
      active,
      existing: activity.existing,
      credit};
    callback(newActivity);
  }, [activity.id, activity.rowIndex, activity.existing, type, minutes, note, active, credit, callback]);

  const updateCredit = (value: number) => {
    setCredit(value);
  }

  const updateMinutes = (value: number) => {
    setMinutes(value);
  }

  return <ActivityGrid container alignContent="">
    <Grid item xs={12} md={8} lg={4}>
      <NoWrapRadioGroup row value={type} onChange={(e) => setType((e.target as HTMLInputElement).value)}>
        <FormControlLabel value={EWorkType.Customer} control={<Radio />} label="Kund" disabled={!active} />
        <FormControlLabel value={EWorkType.Internal} control={<Radio />} label="Interntid" disabled={!active} />
        <FormControlLabel value={EWorkType.Education} control={<Radio />} label="Utb." disabled={!active} />
      </NoWrapRadioGroup>
    </Grid>
    <Grid item xs={12} md={4} lg={3}>
      <PlusMinusMinutes
        initialValue={minutes}
        label=""
        type={EPlusMinusType.Hours}
        callback={updateMinutes}
        smallStep={30}
        largeStep={60}
        allDay={true} />
    </Grid>
    <Grid item xs={11} md={11} lg={4}>
      <TextField
        size="small"
        label="Anteckningar"
        value={note}
        onChange={e => setNote(e.target.value)}
        variant="outlined"
        fullWidth
        disabled={!active}
      />
    </Grid>
    <Grid item xs={1}>
      {active && <IconButton onClick={() => setActive(false)}><RemoveIcon /></IconButton>}
      {!active && <IconButton onClick={() => setActive(true)}><AddIcon /></IconButton>}
    </Grid>
    <Grid item xs={12}>
      {editMode && <PlusMinusMinutes initialValue={credit} label="Tillgodo" callback={updateCredit} />}
    </Grid>
  </ActivityGrid>
}
