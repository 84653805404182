import { useState, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material";

export type SnackbarOptions = {
  message: string;
  severity?: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
};

export const useSnackbar = () => {
  const [snackbar, setSnackbar] = useState<SnackbarOptions | null>(null);

  const showSnackbar = useCallback(
    (options: SnackbarOptions) => {
      setSnackbar({ severity: 'info', duration: 3000, ...options });
    },
    []
  );

  const handleClose = useCallback(() => {
    setSnackbar(null);
  }, []);

  const SnackbarComponent = (
    <Snackbar
      open={!!snackbar}
      autoHideDuration={snackbar?.duration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        style={{ color: "white" }}
        onClose={handleClose}
        severity={snackbar?.severity ?? "info"}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {snackbar?.message}
      </Alert>
  </Snackbar>
  );

  return { showSnackbar, SnackbarComponent };
};
