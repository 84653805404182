import { TextField, styled } from "@mui/material";
import { useEffect, useState } from "react";

const EditTimeTextField = styled(TextField)(() => ({
  width: "75px",
}));

interface EditTimeProps {
  value: Date,
  callback: (value: Date) => void,
}

export const EditTime = ({ value, callback }: EditTimeProps) => {
  const [timeValue, setTimeValue] = useState<string>(`${value.getHours().toString().padStart(2, "0")}:${value.getMinutes().toString().padStart(2, "0")}`)
  const [dateObject, setDateObject] = useState<Date>(value);

  useEffect(() => {
    if (value.toISOString() !== dateObject.toISOString()) {
      setDateObject(value);
    }
  }, [value, dateObject]);

  const updateTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9:]/g, '');

    if (/^\d*$/.test(newValue)) {
      // Empty or digits (hours) only
      if (newValue.length >= 3) {
        // "123" => "12:3", "1234" => "12:34"
        setTimeValue(`${newValue.slice(0, 2)}:${newValue.slice(2)}`);
      } else {
        setTimeValue(newValue);
      }
    } else if (/^\d{0,2}:\d{0,2}$/.test(newValue)) {
      // Valid time (hours and minutes)
      setTimeValue(newValue);
    }
  }

  useEffect(() => {
    const [hours, minutes] = timeValue.split(':');
    if (minutes === undefined) {
      return;
    }
    const updTime = new Date(dateObject);
    updTime.setHours(+hours);
    updTime.setMinutes(+minutes);
    callback(updTime);
  }, [timeValue, callback, dateObject]);

  return <EditTimeTextField value={timeValue} onChange={updateTime} size="small" inputProps={{ maxLength: 5 }} />
}