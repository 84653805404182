import { RadioGroup, Typography, Chip, styled, Box, TableCell, IconButton, Grid, Link, DialogTitle } from '@mui/material';

export const NotUsedTypography = styled(Typography)(() => ({
  color: "rgba(255, 255, 255, 0.5)",
}));
export const WorkHourChip = styled(Chip)(() => ({
  margin: "2px"
}));
export const WorktimeSpan = styled("span")(() => ({
  marginRight: "20px",
}));
export const NoWrapRadioGroup = styled(RadioGroup)(() => ({
  flexWrap: "nowrap",
}));
export const ButtonBox = styled(Box)(() => ({
  /* Desktop */
  position: "absolute",
  width: "20%",
  textAlign: "left",
  /* Mobil */
  "@media (max-width:500px)": {
    position: "inherit",
    width: "inherit",
    textAlign: "center",
    display: "inline-flex",
    marginBottom: "1.5em",
  },
  right: "-10px",
  top: "20px",
  "& > div": {
    marginBottom: "5px",
  },
}));

export const SelectableTableCell = styled(TableCell)(() => ({
  cursor: "pointer",
}));

export const SelectedTableCell = styled(SelectableTableCell)(() => ({
  "& a": {
    color: "#00aa0e",
    fontWeight: "bold",
    textDecoration: "underline",
  },
  cursor: "pointer",
}));

export const EmptyTableCell = styled(TableCell)(() => ({
  color: "#808080",
}));

export const ZeroSpan = styled("span")(() => ({
  color: "#808080",
}));

export const FlexIconButton = styled(IconButton)(() => ({
  "@media (max-width:500px)": {
    paddingLeft: "0px!important",
  },
}));

export const ActivityGrid = styled(Grid)(() => ({
  backgroundColor: "#313131",
  padding: "5px 5px 5px 10px",
  borderRadius: "5px",
  marginBottom: "2px",
}));

export const SummaryLink = styled(Link)(() => ({
  cursor: "pointer",
}))

export const DialogHeaderButtonRight = styled(IconButton)(() => ({
  position: "absolute",
  right: "4px",
}))

export const DialogHeaderButtonLeft = styled(IconButton)(() => ({
  position: "absolute",
  left: "4px",
}))

export const SummaryTableCell = styled(TableCell)(() => ({
  width: "50%",
  textAlign: "end",
  paddingRight: "20%",
}));

export const CenteredDialogTitle = styled(DialogTitle)(() => ({
  textAlign: "center",
}))