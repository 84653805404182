import { useCallback } from "react";
import { authenticatePasskey, registerPasskey } from "../Helpers";
import { SnackbarOptions } from "./useSnackbar";

export const useHandleLogin = (showSnackbar: (options: SnackbarOptions) => void) => {
  const handleRegister = useCallback(async () => {
    try {
        const result = await registerPasskey();
        if (result.success) {
          showSnackbar({message: "Passkey registrerad", severity: "success"});
        } else {
          showSnackbar({message: "Misslyckades att registrera passkey", severity: "error"});
        }
    } catch (error) {
      showSnackbar({message: `Registreringen misslyckades med felet: ${error}`, severity: "error"});
    }
  }, [showSnackbar]);

  const handleLogin = useCallback(async (loginCallback: () => void) => {
    try {
      const result = await authenticatePasskey();
      if (result.verified) {
        showSnackbar({message: "Lyckad inloggning", severity: "info"});
        loginCallback();
      } else {
        showSnackbar({message: "Misslyckad inloggning", severity: "error"});
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        // Passkey not registered, register a new one
        handleRegister();
        return;
      }
      showSnackbar({message: `Inloggningen misslyckades med felet: ${error}`, severity: "error"});
    }
  }, [handleRegister, showSnackbar]);

  return {handleLogin, handleRegister};
};
