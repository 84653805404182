import { IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { formattedWorktime, weekdays } from "../../Helpers";
import { ArrowBackIos as BackIcon, ArrowForwardIos as ForwardIcon, Functions as FunctionsIcon } from '@mui/icons-material';
import { EWorkType, IDate, ISummaryData, ITimeItem } from "./FlexInterfaces";
import { EmptyTableCell, SelectableTableCell, SelectedTableCell, SummaryLink, ZeroSpan } from "./FlexStyle";
import SummaryDialog from "./SummaryDialog";

const getMonday = () => {
  const today = new Date();
  const day = today.getDay(); // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const diff = today.getDate() - day + (day === 0 ? -6 : 1); // Adjust the difference if the current day is Sunday
  return new Date(today.setDate(diff));
};

interface FlexSummaryProps {
  mainDate: Date | null,
  selectedId?: string,
  summaryData: ISummaryData,
  startEditingFunc: (row: ITimeItem) => void,
  updateMainDateFunc: (value: Date) => void,
}

export const FlexSummary = ({ mainDate, selectedId, summaryData, startEditingFunc, updateMainDateFunc }: FlexSummaryProps) => {
  const [showSummaryDialog, setShowSummaryDialog] = useState<boolean>(false);
  const [summaryDate, setSummaryDate] = useState<Date | null>(null);

  useEffect(() => {
    if (mainDate === null) {
      updateMainDateFunc(getMonday());
    }
  }, [mainDate, updateMainDateFunc]);
  
  useEffect(() => {
    setSummaryDate(mainDate);
  }, [mainDate]);

  const moveDate = (days: number) => {
    const newDate = new Date(mainDate.getFullYear(), mainDate.getMonth(), mainDate.getDate() + days);
    updateMainDateFunc(newDate);
  }

  const getDayHeader = (dateData: IDate): string => {
    const d = new Date(dateData.year, dateData.month - 1, dateData.day);
    return `${weekdays[d.getDay()]} ${d.getDate()}/${d.getMonth() + 1}`;
  }

  const startEditing = (event: React.MouseEvent, row: ITimeItem) => {
    event.preventDefault();
    startEditingFunc(row);
  }

  const isSelected = (row: ITimeItem) => {
    return row.id === selectedId;
  }

  const ZeroOrNotTime = (data: { minutes: number }) => {
    const Comp = data.minutes === 0 ? ZeroSpan : "span";
    return <Comp>{formattedWorktime(data.minutes)}</Comp>;
  }

  const worktimeSummary = (row: ITimeItem) => {
    const worktimePerType: { [key in EWorkType]?: number } = {};
    row.activities.forEach((activity) => {
      if (!worktimePerType[activity.type]) {
        worktimePerType[activity.type] = 0;  
      }
      worktimePerType[activity.type] += activity.minutes;
    })
    return <div>
      {formattedWorktime(row.totalWorkMinutes)} (
        <ZeroOrNotTime minutes={worktimePerType[EWorkType.Customer] ?? 0}/>
        {" / "}
        <ZeroOrNotTime minutes={worktimePerType[EWorkType.Internal] ?? 0}/>
        {" / "}
        <ZeroOrNotTime minutes={worktimePerType[EWorkType.Education] ?? 0}/>
      )
    </div>
  }

  const closeSummaryDialog = () => {
    setShowSummaryDialog(false)
    setSummaryDate(mainDate);
  }

  const changeMonth = (monthDiff: number) => {
    setSummaryDate(prev => prev ? new Date(prev.getFullYear(), prev.getMonth() + monthDiff, 1, 0, 0, 0) : prev);
  }

  return <div>
    <SummaryDialog onClose={closeSummaryDialog} show={showSummaryDialog} mainDate={summaryDate} changeMonthCallback={changeMonth} />
    <Typography variant="h4">
      <IconButton onClick={() => moveDate(-7)}><BackIcon/></IconButton>
      Sammanfattning
      <IconButton onClick={() => moveDate(7)}><ForwardIcon/></IconButton>
    </Typography>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Dag</TableCell>
            <TableCell>Arbetstid</TableCell>
            <TableCell>Frånvaro</TableCell>
            <TableCell>Semester</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryData && summaryData.items.map((row: ITimeItem) => {
            const TableDayColumnCellComponent = isSelected(row) ? SelectedTableCell : SelectableTableCell;
            const TableTimeCellComponent = row.existing ? TableCell : EmptyTableCell;
            return (
              <TableRow key={`${row.start.year}-${row.start.month}-${row.start.day}`}>
                <TableDayColumnCellComponent onClick={(e) => startEditing(e, row)}><Link href="#">{getDayHeader(row.start)}</Link></TableDayColumnCellComponent>
                <TableTimeCellComponent>{worktimeSummary(row)}</TableTimeCellComponent>
                <TableTimeCellComponent>{formattedWorktime(row.absenceMinutes)}</TableTimeCellComponent>
                <TableTimeCellComponent>{row.vacation ? "Ja" : "-"}</TableTimeCellComponent>
              </TableRow>
            );
            })}
          {summaryData && <TableRow key="summary">
            <TableCell>
              <SummaryLink onClick={() => setShowSummaryDialog(true)}><FunctionsIcon/></SummaryLink>
            </TableCell>
            <TableCell>
              <ZeroOrNotTime minutes={summaryData.workMinutes[EWorkType.Customer] ?? 0}/>
              {" / "}
              <ZeroOrNotTime minutes={summaryData.workMinutes[EWorkType.Internal] ?? 0}/>
              {" / "}
              <ZeroOrNotTime minutes={summaryData.workMinutes[EWorkType.Education] ?? 0}/>
            </TableCell>
            <TableCell>{formattedWorktime(summaryData.absenceMinutes)}</TableCell>
            <TableCell>{summaryData.vacationDays}</TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
};
