import React, { useCallback, useEffect, useState } from 'react';
import './App.css';
import { BottomNavigation, BottomNavigationAction, Box, CssBaseline, Paper, ThemeProvider, createTheme } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from "axios";
import { FlexComponent } from './components/FlexComponent';
import { MovieComponent } from './components/MovieComponent';
import { Start } from './components/Start';
import { useHandleLogin } from "./hooks/useHandleLogin";
import { useSnackbar } from "./hooks/useSnackbar";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [navigationTab, setNavigationTab] = useState<number>(0);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { handleLogin } = useHandleLogin(showSnackbar);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get('/api/mfa/session-check');
        setLoggedIn(response.data.valid);
      } catch (error) {
        console.error('Error checking session:', error);
        setLoggedIn(false);
      }
    };

    checkSession();
  }, []);

  const NavigationContent = () => {
    switch (navigationTab) {
      case 0: // Flex
        if (loggedIn) {
          return <FlexComponent />
        }
        break;
      case 1: // Movies
        return <MovieComponent />
    }
    return <Start />
  }

  const toggleLoggedInOut = useCallback(async () => {
    if (loggedIn) {
      await axios.post('/api/mfa/logout');
      setTimeout(() => setNavigationTab(0), 0);
      setLoggedIn(prev => false);
    } else {
      const sucessfulLogin = () => {
        setLoggedIn(true);
        setTimeout(() => setNavigationTab(0), 0);
      };
      await handleLogin(sucessfulLogin);
    }
  }, [handleLogin, loggedIn]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <Box sx={{ pb: 7 }} ref={ref}>
          <CssBaseline />
          <NavigationContent />
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
              showLabels
              value={navigationTab}
              onChange={(event, newValue) => {
                setNavigationTab(newValue);
              }}
            >
              <BottomNavigationAction label="Flex" icon={<AccessTimeIcon />} />
              <BottomNavigationAction label="Filmer" icon={<LocalMoviesIcon />} />
              <BottomNavigationAction
                label={loggedIn ? "Logga ut" : "Logga in"}
                icon={loggedIn ? <LoginIcon /> : <LogoutIcon />}
                onClick={toggleLoggedInOut}
              />
            </BottomNavigation>
          </Paper>
        </Box>
      </div>
      {SnackbarComponent}
    </ThemeProvider>
  );
}

export default App;
