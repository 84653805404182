import { formattedTime } from "../../Helpers";
import { EditTime } from "./EditTime";
import { Typography } from "@mui/material";
import { NotUsedTypography } from "./FlexStyle";

interface EndTimeProps {
  editMode: boolean,
  endTime: Date,
  endTimeActivated: boolean,
  callback: (value: Date) => void,
}

export const EndTime = ({ editMode, endTime, endTimeActivated, callback }: EndTimeProps) => {
  
    const currentEndTimeStr = formattedTime(endTime);
    if (!endTimeActivated) {
      return <NotUsedTypography variant="body1">{currentEndTimeStr}</NotUsedTypography>
    } else if (editMode) {
      return <EditTime value={endTime} callback={callback} />
    }
   return <Typography variant="body1">{currentEndTimeStr}</Typography>
};
