import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chip, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';

interface ChipStyle {
  backgroundColor?: string,
  color?: string,
  marginLeft: string,
  cursor: string,
  borderColor?: string,
}

interface Genre {
  id: number,
  name: string,
  color_background: string,
  color_font: string,
}

interface Movie {
  id: number,
  alternative_title: string,
  original_title: string,
  production_year: number,
  imdb_link: string,
  genres: number[],
}

export const MovieComponent = () => {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [genreMapping, setGenreMapping] = useState<{ [id: number]: Genre }>({});
  const [filteredMovies, setFilteredMovies] = useState<Movie[]>([]);
  const [filter, setFilter] = useState<string>('');
  const [selectedGenre, setSelectedGenre] = useState<number | null>(null);

  const fetchMovies = async () => {
    try {
      const response = await axios.get("/api/movies/");
      setMovies(response.data);
    } catch (error) {
      console.error('Error fetching movies:', error);
    }
  };

  const fetchGenres = async () => {
    try {
      const response = await axios.get("/api/movies/genres");
      const mappedGenres: { [id: number]: Genre } = {};
      response.data.forEach((genre: Genre) => {
        mappedGenres[genre.id] = genre;
      });
      setGenreMapping(mappedGenres);
    } catch (error) {
      console.error('Error fetching genres:', error);
    }
  };

  useEffect(() => {
    fetchMovies();
    fetchGenres();
  }, []);

  useEffect(() => {
    setFilteredMovies(
      // Filtering on titles and/or genres
      movies.filter(movie =>
        (movie.alternative_title.toLowerCase().includes(filter.toLowerCase()) ||
        movie.original_title.toLowerCase().includes(filter.toLowerCase())) &&
        (selectedGenre === null || movie.genres.includes(selectedGenre))
      )
    );
  }, [movies, filter, selectedGenre]);

  const toggleGenreFilter = (genreId: number) => {
    setSelectedGenre(prev => prev === genreId ? null : genreId);
  }

  const getChipStyle = (genreId: number) => {
    const genre = genreMapping[genreId];
    const baseStyle: ChipStyle = {
      marginLeft: "5px",
      cursor: "pointer"
    }
    if (selectedGenre === genreId) {
      baseStyle["borderColor"] = genre.color_background;
    } else {
      baseStyle["backgroundColor"] = genre.color_background;
      baseStyle["color"] = genre.color_font;
    }
    return baseStyle;
  }

  return (
    <div>
      <TextField
        label="Filtrera p&aring; titel"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Titel</TableCell>
              <TableCell>Originaltitel</TableCell>
              <TableCell>Produktions&aring;r</TableCell>
              <TableCell>Genres</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredMovies.map((movie, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link href={movie.imdb_link} target="_blank" rel="noreferrer">{movie.alternative_title}</Link>
                </TableCell>
                <TableCell>{movie.original_title}</TableCell>
                <TableCell>{movie.production_year}</TableCell>
                <TableCell>
                  {movie.genres.map((genreId) => {
                    const genre = genreMapping[genreId];
                    return <Chip key={genreId} size="small" label={genre.name}
                      variant={selectedGenre === genreId ? "outlined" : "filled"}
                      onClick={() => toggleGenreFilter(genreId)}
                      style={getChipStyle(genreId)}></Chip>
                  }
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
