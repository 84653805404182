import { Grid, IconButton, Switch, Typography, styled } from "@mui/material";
import { AddCircle as AddIcon, RemoveCircle as RemoveIcon, Today as TodayIcon } from '@mui/icons-material';
import { useEffect, useState } from "react";
import { formattedWorktime } from "../../Helpers";

const PlusMinusLabel = styled("div")(() => ({
  paddingTop: "8px",
}));

const LabelGrid = styled(Grid)(() => ({
  textAlign: "left",
}))

export enum EPlusMinusType {
  Minutes = "min",
  Hours = "h",
}

interface PlusMinusProps {
  initialValue: number,
  label: string,
  type?: EPlusMinusType,
  useSwitch?: boolean,
  smallStep?: number,
  largeStep?: number,
  callback: (value: number) => void,
  allDay?: boolean, /* If showing a button for updating the value to all day (i.e. 8*60 minutes = 480 minutes) */
}

export const PlusMinusMinutes = ({ initialValue, label, type, useSwitch, smallStep, largeStep, callback, allDay }: PlusMinusProps) => {
  const [value, setValue] = useState<number>(initialValue);
  const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout>();

  const updateValue = (value: number) => {
    setTimeout(() => {
      callback(value);
    }, 0);
  }

  const handleDoubleClick = (increment: boolean) => {
    clearTimeout(clickTimeout);
    setClickTimeout(undefined);
    setValue((prevValue) => {
      const newValue = prevValue + (increment ? (largeStep ?? 10) : -(largeStep ?? 10));
      const result = newValue < 0 ? 0 : newValue;
      updateValue(result);
      return result;
    });
  }

  const handleClick = (increment: boolean) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout!);
    }
    const timeout = setTimeout(() => {
      setValue((prevValue) => {
        const newValue = prevValue + (increment ? (smallStep ?? 5) : -(smallStep ?? 5));
        if (useSwitch && newValue > 1) {
          return 1;
        }
        const result = newValue < 0 ? 0 : newValue;
        updateValue(result);
        return result;
      });
      setClickTimeout(undefined);
    }, 300);
    setClickTimeout(timeout);
  };

  const handleToggle = () => {
    setValue(prev => {
      const value = prev === 1 ? 0 : 1;
      updateValue(value);
      return value;
    });
  }

  const formatValue = (value: number) => {
    if (type === EPlusMinusType.Hours) {
      return formattedWorktime(value);
    }
    return value;
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <Grid container>
    {label !== "" && <LabelGrid item xs={4} md={2} lg={2}>
      <Typography variant="body1">{label}:</Typography>
    </LabelGrid>}
    {!useSwitch && <Grid item>
      <IconButton onClick={(e) => handleClick(false)} onDoubleClick={(e) => handleDoubleClick(false)}>
        <RemoveIcon />
      </IconButton>
    </Grid>}
    <Grid item>
      {useSwitch && <Switch checked={value === 1} onChange={() => handleToggle()} />}
      {!useSwitch && <PlusMinusLabel>{formatValue(value)} {type ?? EPlusMinusType.Minutes}</PlusMinusLabel>}
    </Grid>
    {!useSwitch && <Grid item>
      <IconButton onClick={(e) => handleClick(true)} onDoubleClick={(e) => handleDoubleClick(true)}>
        <AddIcon />
      </IconButton>
    </Grid>}
    {allDay && <Grid item>
      <IconButton onClick={() => callback(480)} title="Hela dagen"><TodayIcon /></IconButton>
    </Grid>}
  </Grid>
};
