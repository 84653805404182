import axios from "axios";

export const formattedTime = (time: Date | null) => {
  if (!time) return '';

  const hours = time.getHours().toString().padStart(2, "0");
  const minutes = time.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const formattedWorktime = (minutes: number) => {
  const negative = minutes < 0 ? "-" : "";
  minutes = Math.abs(minutes)
  const hours = Math.floor(minutes / 60);
  const reminder = (minutes % 60).toString().padStart(2, "0");
  return `${negative}${hours}:${reminder}`;
};

export const weekdays = ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"];
export const months = ["Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December"];

const bufferToBase64 = (buffer: ArrayBuffer): string => {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)));
}
const base64urlToBase64 = (base64url: string) => {
  return base64url.replace(/-/g, "+").replace(/_/g, "/") + "=".repeat((4 - (base64url.length % 4)) % 4);
}

/** WebAuthn */
export async function registerPasskey() {
  // Step 1: Get WebAuthn Challenge from Backend
  const challengeRes = await axios.get("/api/mfa/register/options");
  const challengeData = challengeRes.data;
      
  // Convert challenge & user.id to ArrayBuffers
  const challengeBase64 = base64urlToBase64(challengeData.challenge);
  const challengeArray = new Uint8Array(atob(challengeBase64).split("").map(c => c.charCodeAt(0)));

  challengeData.challenge = challengeArray.buffer; // Convert to ArrayBuffer
  challengeData.user.id = Uint8Array.from(atob(challengeData.user.id), c => c.charCodeAt(0)); 

  // Step 2: Create WebAuthn Credentials
  const publicKeyCredential = await navigator.credentials.create({
    publicKey: {
      challenge: challengeArray.buffer,
      rp: challengeData.rp,
      user: {
        id: Uint8Array.from(atob(challengeData.user.id), c => c.charCodeAt(0)),
        name: challengeData.user.name,
        displayName: challengeData.user.displayName
      },
      pubKeyCredParams: challengeData.pubKeyCredParams,
      authenticatorSelection: { residentKey: "preferred", userVerification: "preferred" }
    }
  });

  if (!publicKeyCredential) {
    throw new Error("WebAuthn registration failed");
  }

  // Step 3: Encode Response Correctly for Backend
  const credential = publicKeyCredential as PublicKeyCredential;
  const response = credential.response as AuthenticatorAttestationResponse;

  const dataToSend = {
    id: credential.id,
    raw_id: bufferToBase64(credential.rawId),  // Base64 encode
    type: credential.type,
    response: {
      clientDataJSON: bufferToBase64(response.clientDataJSON),
      attestationObject: bufferToBase64(response.attestationObject)
    }
  };

  // Send to backend for verification
  const verifyRes = await axios.post("/api/mfa/register/verify", dataToSend);
  return verifyRes.data;
}

export async function authenticatePasskey() {
  const res = await axios.get("/api/mfa/options");
  const challengeData = res.data;

  const challengeArray = new Uint8Array(atob(base64urlToBase64(challengeData.challenge)).split("").map(c => c.charCodeAt(0)));

  // Convert challenge to ArrayBuffer
  challengeData.challenge = challengeArray.buffer; // Convert to ArrayBuffer

  // Authenticate with stored passkey
  const credential = await navigator.credentials.get({
    publicKey: {
      challenge: challengeArray.buffer,
      rpId: challengeData.rp_id,
      allowCredentials: challengeData.allowCredentials.map((cred: any) => ({
        id: new Uint8Array(atob(base64urlToBase64(cred.id)).split("").map(c => c.charCodeAt(0))).buffer,
        type: cred.type
      })),
      userVerification: challengeData.user_verification
    }
  }) as PublicKeyCredential;
  // Convert response to Base64 for transmission
  const data = {
    id: credential.id,
    rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
    response: {
      authenticatorData: btoa(String.fromCharCode(...new Uint8Array((credential.response as AuthenticatorAssertionResponse).authenticatorData))),
      clientDataJSON: btoa(String.fromCharCode(...new Uint8Array((credential.response as AuthenticatorAssertionResponse).clientDataJSON))),
      signature: btoa(String.fromCharCode(...new Uint8Array((credential.response as AuthenticatorAssertionResponse).signature))),
    },
    type: credential.type
  };

  // Send to backend for verification
  const verifyRes = await axios.post("/api/mfa/verify", data);
  return verifyRes.data;
}
