export interface IDate {
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
}

export const enum EWorkType {
  Customer = "Customer",
  Internal = "Internal",
  Education = "Education",
}

export interface IActivity {
  id?: string,
  rowIndex: number,
  type: EWorkType,
  minutes: number,
  note: string,
  credit: number,
  active: boolean,
  existing: boolean,
}

export interface ITimeItem {
  id?: string,
  start: IDate,
  end?: IDate,
  totalWorkMinutes: number,
  lunchMinutes: number,
  absenceMinutes: number,
  vacation: boolean,
  activities: IActivity[],
  existing: boolean,
}

export interface ISummaryData {
  workMinutes: number,
  absenceMinutes: number,
  vacationDays: number,
  items: ITimeItem[],
}

export interface ISaveResult {
  id: string,
  affectedItems: number,
  affectedActivities: number,
}

export interface IMonthSummary {
  absenceMinutes: number,
  vacationDays: number,
  flexMinutes: number,
  summary: {[key in EWorkType]: number},
}
