import { useCallback, useEffect, useState } from "react";
import { FlexSummary } from "./Flex/FlexSummary";
import FlexToday from "./Flex/FlexToday";
import { ISaveResult, ISummaryData, ITimeItem } from "./Flex/FlexInterfaces";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "../hooks/useSnackbar";


const getEmptyItem = (): ITimeItem => {
  const now = new Date();
  return {
    id: uuidv4(),
    start: {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
      hour: now.getHours(),
      minute: now.getMinutes(),
    },
    end: undefined,
    totalWorkMinutes: 0,
    lunchMinutes: 60,
    absenceMinutes: 0,
    vacation: false,
    existing: false,
    activities: []
  };
};

export const FlexComponent = () => {
  const [item, setItem] = useState<ITimeItem>(getEmptyItem());
  const [mainDate, setMainDate] = useState<Date | null>(null);
  const [summaryData, setSummaryData] = useState<ISummaryData | null>(null);
  const { showSnackbar, SnackbarComponent } = useSnackbar();

  const actions = {
    fetchSummary: useCallback(async (selectFirst: boolean)  => {
      try {
        const response = await axios.get<ISummaryData>(`/api/flex/summary/${mainDate.getFullYear()}/${mainDate.getMonth() + 1}/${mainDate.getDate()}`);
        setSummaryData(response.data);
        if (selectFirst) {
          // If current week, select today
          const today = new Date();
          const nextMonday = new Date(mainDate.getFullYear(), mainDate.getMonth(), mainDate.getDate() + 7, 0, 0, 0, 0);
          let itemIndex = 0;
          if (mainDate <= today && today < nextMonday) {
            itemIndex = Math.floor((today.getTime() - mainDate.getTime()) / (24*3_600_000))
          }
          // Check so it's a work day (index 5 and 6 are weekend)
          if (response.data.items[itemIndex]) {
            const newItem = {...response.data.items[itemIndex]};
            if (!newItem.existing) {
              // Add current time for non-existing item
              const now = new Date();
              newItem.start.hour = now.getHours();
              newItem.start.minute = now.getMinutes();
            }
            setItem(response.data.items[itemIndex]);
          }
        }
        return response.data;
      } catch (error) {
        console.error('Error fetching summary:', error);
      }
    }, [mainDate]),
    updateMainDate: useCallback((value: Date) => {
      setMainDate(value);
    }, []),
    save: useCallback(async (row: ITimeItem) => {
      const result = await axios.post<ISaveResult>("/api/flex/", row);
      const data = await actions.fetchSummary(false);
      const item = data.items.filter((x) => x.id === result.data.id)[0];
      if (item) {
        actions.editRow(item);
        showSnackbar({ message: 'Sparat', severity: 'success' });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainDate]),
    reset: useCallback(() => {
      setItem(getEmptyItem());
    }, []),
    editRow: useCallback((item: ITimeItem) => {
      if (!item.existing) {
        // Overwrite empty item with current time
        const now = new Date();
        item.start.hour = now.getHours();
        item.start.minute = now.getMinutes();
      }
      setItem(item);
    }, []),
  };

  useEffect(() => {
    if (mainDate) {
      actions.fetchSummary(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions.fetchSummary, mainDate]);

  return <div>
    <FlexSummary
      mainDate={mainDate}
      selectedId={item.id}
      summaryData={summaryData}
      startEditingFunc={actions.editRow}
      updateMainDateFunc={actions.updateMainDate} />
    <FlexToday
      item={item}
      saveCallback={actions.save}
      resetCallback={actions.reset} />
    {SnackbarComponent}
  </div>
};
