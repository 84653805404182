import { Dialog, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { ArrowBackIos as BackIcon, ArrowForwardIos as ForwardIcon } from '@mui/icons-material';
import { formattedWorktime, months } from "../../Helpers";
import { useEffect, useState } from "react";
import { EWorkType, IMonthSummary } from "./FlexInterfaces";
import axios from "axios";
import { CenteredDialogTitle, DialogHeaderButtonLeft, DialogHeaderButtonRight, SummaryTableCell } from "./FlexStyle";

export interface SimpleDialogProps {
  show: boolean;
  mainDate: Date | null;
  onClose: () => void;
  changeMonthCallback: (monthDiff: number) => void;
}

const SummaryDialog = ({ show, mainDate, onClose, changeMonthCallback}: SimpleDialogProps) => {
  const [monthSummary, setMonthSummary] = useState<IMonthSummary | null>(null);

  useEffect(() => {
    if (!show) {
      return;
    }

    const getMonthSummary = async () => {
      const response = await axios.get<IMonthSummary>(`/api/flex/monthsummary/${mainDate.getFullYear()}/${mainDate.getMonth() + 1}`);
      setMonthSummary(response.data);
    }

    getMonthSummary();
  }, [mainDate, show]);

  const handleClose = () => {
    onClose();
  };

  if (!mainDate) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={show} fullWidth={true}>
      <CenteredDialogTitle>
        <DialogHeaderButtonLeft onClick={() => changeMonthCallback(-1)}><BackIcon/></DialogHeaderButtonLeft>
        Summering: {months[mainDate.getMonth()]} {mainDate.getFullYear()}
        <DialogHeaderButtonRight onClick={() => changeMonthCallback(1)}><ForwardIcon/></DialogHeaderButtonRight>
      </CenteredDialogTitle>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Kund:</TableCell>
            <SummaryTableCell>{formattedWorktime(monthSummary?.summary[EWorkType.Customer] ?? 0)}</SummaryTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Interntid:</TableCell>
            <SummaryTableCell>{formattedWorktime(monthSummary?.summary[EWorkType.Internal] ?? 0)}</SummaryTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Utb:</TableCell>
            <SummaryTableCell>{formattedWorktime(monthSummary?.summary[EWorkType.Education] ?? 0)}</SummaryTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Frånvaro:</TableCell>
            <SummaryTableCell>{formattedWorktime(monthSummary?.absenceMinutes ?? 0)}</SummaryTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Semester:</TableCell>
            <SummaryTableCell>{monthSummary?.vacationDays ?? 0}</SummaryTableCell>
          </TableRow>
          <TableRow>
            <TableCell>Flex:</TableCell>
            <SummaryTableCell>{formattedWorktime(monthSummary?.flexMinutes ?? 0)}</SummaryTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Dialog>
  );
}

export default SummaryDialog;
