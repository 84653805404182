import { Box } from "@mui/material";
import logo from '../logo.svg';
import { styled } from '@mui/system';

const copyRightYear = process.env.REACT_APP_CURRENT_YEAR;

const StyledSVGContainer = styled(Box)({
  "& img": {
    maxHeight: '75vh',
  },
});

export const Start = () => {
  return <div>
    <StyledSVGContainer>
      <img src={logo} className="M-logo" alt="logo" />
    </StyledSVGContainer>
    <div>Copyright &copy; {copyRightYear} Mr M (v. {process.env.REACT_APP_GIT_HASH})</div>
  </div>
}
